import React from "react"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo/seo"
import { GenericHero } from "../components/hero/GenericHero"
import { ContentBlocks } from "../components/content-blocks/ContentBlocks"
import { GlobalFooter } from "../components/global-footer/GlobalFooter"

type PageContext = PageProps["pageContext"] & {
  breadcrumb: {
    crumbs: Record<string, string>[]
    location: string
  }
  linkedPagesUrlPathCodex: Record<string, string>
}

interface NotFoundPageProps extends PageProps {
  pageContext: PageContext
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  location,
  pageContext,
}) => {
  const { pathname } = location
  const { linkedPagesUrlPathCodex } = pageContext
  const { allKontentItemPage } = useStaticQuery(get404)
  const [errorPage] = allKontentItemPage.nodes

  // const { banner, rich_content, global_footer } = errorPage?.elements
  const banner = errorPage?.elements?.banner
  const rich_content = errorPage?.elements?.rich_content
  const global_footer = errorPage?.elements?.global_footer

  return (
    <Layout
      location="/404"
      pathName={pathname}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
    >
      <SEO title="404: Not found" />
      {banner && <GenericHero banner={banner.value[0].elements} />}

      {rich_content?.modular_content?.length > 0 && (
        <ContentBlocks content={rich_content.modular_content} />
      )}

      {global_footer && global_footer?.value.length > 0 && (
        <GlobalFooter content={global_footer} />
      )}
    </Layout>
  )
}

const get404 = graphql`
  query FourOhFourPage {
    allKontentItemPage(
      filter: { elements: { slug: { value: { eq: "404" } } } }
    ) {
      nodes {
        elements {
          banner {
            value {
              ...HeroBannerFragment
            }
          }
          rich_content {
            ...ContentBlocksParserFragment
          }
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage
